



import {
  defineComponent,
  useMeta,
  computed,
  useContext,
} from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'JoinAccountManagementPlan',
  layout: 'cellar-plan',
  middleware: 'is-authenticated',
  setup() {
    const context = useContext();

    const metaTags = computed(() => ({
      title: `${context.app.i18n.t('Join account management plan')} | Berry Bros. & Rudd`,
      meta: [
        {
          hid: 'og:title',
          property: 'og:title',
          content: `${context.app.i18n.t('Join account management plan')} | Berry Bros. & Rudd`
        },
      ]
    }));

    useMeta(metaTags.value);

    return {
    };
  },
  head: {},
});
